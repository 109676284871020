"use client"

import { useEffect, useRef, useState } from "react"
import { PopupClient } from "."
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { ArrowRightIcon, CloseIcon } from "../ui/icons"

export type FocusableElement = HTMLDivElement

const SmsPopupWrapper = () => {
  const [open, setOpen] = useState(false)

  const ref = useRef(null)
  const searchParams = useSearchParams()
  const router = useRouter()
  const pathname = usePathname()

  const smsParam = searchParams.get("sms")
  const smsUrl = `sms:36912?body=${smsParam}`

  const handleClose = () => {
    // This removes SMS form URL - but it's creating concunrrency!!
    // const nextSearchParams = new URLSearchParams(searchParams.toString())
    // nextSearchParams.delete("sms")
    // router.replace(`${pathname}?${nextSearchParams}`)

    if (window || document) document.body.style.overflow = "auto"
    setOpen(false)
  }

  useEffect(() => {
    if (!window || !document) return

    // Check for mobile. `useIsMobile` ain't working!!
    if (window.innerWidth > 768) {
      handleClose()
      return
    }

    if (smsParam && smsParam?.length > 0) {
      setOpen(true)
      document.body.style.overflow = "hidden"
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PopupClient open={open} close={() => handleClose()} ref={ref}>
      <div className="flex flex-col items-center justify-center">
        <h2 className="mb-2 text-xl text-center">Limited Time Discount:</h2>
        <p className="mb-6 text-2xl">
          Text <span className="font-bold uppercase">{smsParam}</span> to
        </p>
        <p className="px-6 py-3 font-mono text-6xl bg-gray-800 border-[2px] border-gray-600 rounded-lg font-bold mb-6">
          36912
        </p>
        <p className="mb-6 text-2xl uppercase">
          For <span className="font-bold">60% OFF</span>
        </p>
        <div className="flex flex-col mt-6">
          <a
            href={smsUrl}
            className="block w-full px-6 py-4 mb-4 text-lg text-center bg-green-600 rounded-lg"
          >
            <div className="flex flex-row items-center justify-center w-full">
              <ArrowRightIcon className="h-3 mr-2" />
              GET 60% OFF
            </div>
            <span className="text-base opacity-60">
              (redirects to sms application)
            </span>
          </a>
          <button
            className="block w-full px-6 py-4 mb-4 text-lg text-center bg-red-500 rounded-lg "
            onClick={() => handleClose()}
          >
            <div className="flex flex-row items-center justify-center w-full uppercase opacity-80">
              <CloseIcon className="h-3 mr-2" />
              Continue to website
            </div>
          </button>
        </div>
      </div>
    </PopupClient>
  )
}

export { SmsPopupWrapper }
