"use client"

import { classNames } from "@local/utils"
import { useId, type ReactNode } from "react"
import { CloseIcon } from "../ui/icons"

interface Props {
  children?: ReactNode
  open: boolean
  close: () => void
  ref: React.MutableRefObject<HTMLDivElement | null>
}

export const PopupClient = ({ open, children, close, ref }: Props) => {
  if (!open) {
    return null
  }

  const id = useId()

  return (
    <div
      className={classNames(
        "fixed inset-0 overflow-y-auto box-border z-[99999] bg-gray-500 bg-opacity-75 backdrop-blur-sm p-3 pb-0",
        "w-[100vw], !h-[100vh]"
      )}
      ref={ref}
      onClick={() => close()}
      id={`smspopup-${id}`}
    >
      <div className="flex items-center justify-center w-full h-full max-w-4xl m-auto mx-auto">
        <div
          className="relative py-24 px-5 bg-black z-[99999999] text-white w-[400px] rounded-lg"
          onClick={(e) => e.stopPropagation()}
        >
          <button className="absolute top-4 right-4" onClick={close}>
            <CloseIcon />
            <span className="invisible hidden w-0 h-0">Close</span>
          </button>
          {children}
        </div>
      </div>
    </div>
  )
}
