import { classNames } from "@local/utils"

interface Props {
  className?: string
}

export const ArrowLeftIcon = ({ className }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 73 59"
    className={className}
    aria-hidden="true"
  >
    <path
      d="M37.0366 7.90828L21.3142 23.6316L72.101 23.6316L72.101 34.8162L21.8071 34.8162L37.533 50.5403L29.6236 58.4487L1.01927e-05 29.1279L29.1281 2.29461e-05L37.0366 7.90828Z"
      fill="#E8E8E8"
    />
  </svg>
)

export const ArrowRightIcon = ({ className }: Props) => (
  <ArrowLeftIcon
    className={classNames("rotate-180", className ? className : "")}
  />
)

export const CloseIcon = ({ className }: Props) => (
  <svg
    width="1.063em"
    height="1.063em"
    viewBox="0 0 17 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    aria-hidden="true"
  >
    <path
      d="M16 1L1 16"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M0.999999 1L16 16"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
